import React, { useState } from "react";
import logo from "../resources/FF111.png";
import { BiPaperPlane } from "react-icons/bi";

const Header = () => {
  const [navbar, setNavbar] = useState(false);

  return (
    <>
      <header className="w-full h-[80px] leading-[80px] flex items-center">
        <div className="container">
          <div className="flex items-center justify-between">
            {/* Logo */}
            <div className="flex items-center ">
              <img src={logo} className="w-[50px] h-[50px]" />
            </div>

            {/* Menu */}
            <div className="menu">
              <ul className="flex items-center gap-8">
                <li className="">
                  <a
                    className="uppercase font-semibold text-xs text-gray-800 hover:bg-[#211033] hover:text-white hover:border border-[#211033] ease in duration-200 max-h-[40px] px-3 py-3 rounded-md"
                    href="#"
                  >
                    About
                  </a>
                </li>
                <li className="">
                  <a
                    className="uppercase font-semibold text-xs text-gray-800 hover:bg-[#211033] hover:text-white hover:border border-[#211033] ease in duration-200 max-h-[40px] px-3 py-3 rounded-md"
                    href="#"
                  >
                    Services
                  </a>
                </li>
                <li className="">
                  <a
                    className="uppercase font-semibold text-xs text-gray-800 hover:bg-[#211033] hover:text-white hover:border border-[#211033] ease in duration-200 max-h-[40px] px-3 py-3 rounded-md"
                    href="#"
                  >
                    Portfolio
                  </a>
                </li>
                <li className="">
                  <a
                    className="text-[#e83310] uppercase font-semibold text-xs  hover:bg-[#211033] hover:text-white hover:border border-[#211033] ease in duration-200 max-h-[40px] px-3 py-3 rounded-md"
                    href="#"
                  >
                    Learn
                  </a>
                </li>
                <li className="">
                  <a
                    className="uppercase font-semibold text-xs text-gray-800 hover:bg-[#211033] hover:text-white hover:border border-[#211033] ease in duration-200 max-h-[40px] px-3 py-3 rounded-md"
                    href="#"
                  >
                    Contact
                  </a>
                </li>
              </ul>
            </div>

            {/* button */}
            <div className="flex items-center gap-4">
              <button className="flex text-xs items-center gap-2 font-semibold bg-[#211033] text-white border border-teal-700 rounded-xl py-2 px-4 max-h-[40px] hover:bg-white hover:text-[#211033] ease-in duration-300">
                <BiPaperPlane />
                <a href="tel:01744353576">Let's Talk</a>
              </button>
            </div>
          </div>
        </div>
      </header>
      {/* 
<nav className="w-full shadow">
      <div className="justify-between px-4 mx-auto lg:max-w-7xl md:items-center md:flex md:px-8">
        <div>
          <div className="flex items-center justify-between py-3 md:py-5 md:block">
            <a href="#">
              <img src={logo} className="w-20" />
            </a>
            <div className="md:hidden">
              <button
                className="p-2 text-gray-700 rounded-md outline-none focus:border-gray-400 focus:border"
                onClick={() => setNavbar(!navbar)}
              >
                {navbar ? (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="w-6 h-6"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path
                      fillRule="evenodd"
                      d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                      clipRule="evenodd"
                    />
                  </svg>
                ) : (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="w-6 h-6"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M4 6h16M4 12h16M4 18h16"
                    />
                  </svg>
                )}
              </button>
            </div>
          </div>
        </div>
        <div>
          <div
            className={`flex-1 justify-self-center pb-3 mt-8 md:block md:pb-0 md:mt-0 ${
              navbar ? "block" : "hidden"
            }`}
          >
            <ul className="items-center justify-center space-y-8 md:flex md:space-x-6 md:space-y-0">
              <li className="text-gray-600 hover:text-blue-600">
                <a href="javascript:void(0)">Home</a>
              </li>
              <li className="text-gray-600 hover:text-blue-600">
                <a href="javascript:void(0)">Blog</a>
              </li>
              <li className="text-gray-600 hover:text-blue-600">
                <a href="javascript:void(0)">About US</a>
              </li>
              <li className="text-gray-600 hover:text-blue-600">
                <a href="javascript:void(0)">Contact US</a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </nav> */}
    </>
  );
};

export default Header;

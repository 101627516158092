import React from "react";
import CountUp from "react-countup";

import bannerImg from "../resources/bannerImg.png";
import { Typewriter } from "react-simple-typewriter";
import { BiMailSend } from "react-icons/bi";
import { FaFacebook, FaInstagram, FaGithub, FaWhatsapp } from "react-icons/fa";

const MainSection = () => {
  return (
    <>
      <section className="pt-0">
        <div className="container pt-6">
          <div className="md:flex items-center justify-between sm:flex-col md:flex-row">
            <div className="w-full md:basis-1/2">
              <h5
                className="font-semibold"
                data-aos="fade-right"
                data-aos-duration="1500"
              >
                Hi! There
              </h5>
              <h1
                data-aos="fade-up"
                data-aos-duration="1500"
                className="font-semibold text-[1.8rem] sm:text-[40px] leading-[35px] sm:leading-[46px] mt-5"
              >
                I'm{" "}
                <span style={{ color: "#e83310" }}>
                  <Typewriter
                    words={[
                      "Fahim Ferdous",
                      "A Teacher",
                      "A Software Developer",
                      "A UI/UX Designer",
                    ]}
                    loop={1000}
                    cursor
                    cursorStyle="|"
                    typeSpeed={70}
                    deleteSpeed={50}
                    delaySpeed={1000}
                  />
                </span>
              </h1>
              <div
                data-aos="fade-up"
                data-aos-delay="200"
                data-aos-duration="1800"
                className="flex items-center gap-5 mt-5"
              >
                <button className="flex text-white text-xs items-center gap-2text-white bg-gradient-to-r from-cyan-500 to-blue-500 hover:bg-gradient-to-bl focus:ring-4 focus:outline-none focus:ring-cyan-300 dark:focus:ring-cyan-800 font-medium rounded-lg px-5 py-2.5 text-center">
                  <a className="flex items-center gap-2">
                    <BiMailSend />
                    Hire Me
                  </a>
                </button>
                <a className="font-semibold text-14 border-b border-solid border-orange-700">
                  See Portfolio
                </a>
              </div>
              <p
                className="flex text-gray-800 font-medium mt-6 text-xs leading-5 pr-14 "
                data-aos="fade-left"
                data-aos-duration="1500"
              >
                <span></span>A passionate and professional web app developer and
                a certified UI/UX designer. I also teach computer science
                subject to O & A level students.
              </p>
              <div
                className="flex items-center gap-9 mt-10"
                data-aos="fade-right"
                data-aos-duration="1500"
              >
                <span className="text-gray-800 text-sm font-semibold">
                  Find Me:{" "}
                </span>
                <span>
                  <a
                    href="#"
                    className="text-gray-800 text-[18px] font-semibold hover:text-gray-950"
                  >
                    <FaFacebook />
                  </a>
                </span>
                <span>
                  <a
                    href="#"
                    className="text-gray-800 text-[18px] font-semibold hover:text-gray-950"
                  >
                    <FaInstagram />
                  </a>
                </span>
                <span>
                  <a
                    href="#"
                    className="text-gray-800 text-[18px] font-semibold hover:text-gray-950"
                  >
                    <FaWhatsapp />
                  </a>
                </span>
                <span>
                  <a
                    href="#"
                    className="text-gray-800 text-[18px] font-semibold hover:text-gray-950"
                  >
                    <FaGithub />
                  </a>
                </span>
              </div>
            </div>
            <div
              className="basis-1/2 "
              data-aos="fade-left"
              data-aos-duration="1500"
            >
              <figure className="flex">
                <img src={bannerImg} />
              </figure>
            </div>
          </div>
        </div>
      </section>
      <section className="pt-0">
        <div className="container pt-6">
          <div className="flex items-center justify-center gap-20">
            {/* Card */}
            <div class=" p-4 bg-gradient-to-br from-sky-400 to-sky-500 border border-gray-200 rounded-lg shadow flex items-center justify-center flex-col">
              <h5 class="mb-2 text-xl font-bold tracking-tight text-white">
                300+
              </h5>

              <p class="mb-1 font-normal text-gray-100 text-xs">
                Graduate Students
              </p>
            </div>
            {/* Card */}
            <div class=" p-4 bg-gradient-to-br from-indigo-500 to-indigo-400 border  rounded-lg shadow flex items-center justify-center flex-col">
              <h5 class="mb-2 text-xl font-bold tracking-tight text-gray-50">
                12+
              </h5>

              <p class="mb-1 font-normal text-gray-100 text-xs">
                Happy Clients
              </p>
            </div>
            {/* Card */}
            <div class=" p-4 bg-gradient-to-br from-cyan-400 to-cyan-500 border border-gray-200 rounded-lg shadow flex items-center justify-center flex-col">
              <h5 class="mb-2 text-xl font-bold tracking-tight text-gray-50">
                6+
              </h5>

              <p class="mb-1 font-normal text-gray-100 text-xs">
                Years Experience
              </p>
            </div>
            {/* Card */}
            <div class=" p-4 bg-gradient-to-br from-teal-400 to-teal-500 border border-gray-200 rounded-lg shadow flex items-center justify-center flex-col">
              <h5 class="mb-2 text-xl font-bold tracking-tight text-gray-50">
                15+
              </h5>

              <p class="mb-1 font-normal text-gray-100 text-xs">
                Completed Projects
              </p>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default MainSection;
